<template>
  <div>
    <nav-header></nav-header>
    <div class="main">
      <div  class="envCon" :class="lang">
        <div class="hr-protect feature">
          <h4>{{$t('hr.hrtitle')}}</h4>
          <div class="hr-flex">
            <div class="hr-protect-left ">
              <div class="hr-protect-left-lefts">
                <p>{{$t('hr.hrcontent')}}</p>
              </div>
              <div class="hr-left-flex">
                <div class="hr-protect-left-left clearfix">
                  <span style="display: block;color: #353535;">{{zh_data.date}}{{$t('hr.hryear')}}</span>
                  <span>{{zh_data.employee}}</span>
                  <span>{{$t('hr.people')}}</span>
                </div>
                <div class="hr-protect-left-right clearfix">
                  <span style="display: block;color: #353535;">{{$t('hr.hrcontact')}}</span>
                  <span>100</span>
                  <span>%</span>
                </div>

              </div>

            </div>
          </div>
          <div class="age common" v-if="this.$i18n.locale=='zh'">
            <span>员工年龄比例</span>
            <!-- <img src="/img/hr/1.bmp" alt=""> -->
            <div class="age-detail">
              <ul>
                  <li>
                      <span>30及以下</span>
                       <span>20.63%</span>
                  </li>
                   <li>
                      <span>31-39</span>
                       <span>42.47%</span>
                  </li>
                   <li>
                      <span>40及以上</span>
                       <span>36.90%</span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="age common"  v-if="this.$i18n.locale=='en'">
            <span>Total number of employees by age</span>
            <!-- <img src="/img/hr/enage0322.png" alt=""> -->
            <div class="age-detail">
              <ul>
                <li>
                      <span>30 and under</span>
                       <span>20.63%</span>
                  </li>
                   <li>
                      <span>31-39</span>
                       <span>42.47%</span>
                  </li>
                   <li>
                      <span>40 and above</span>
                       <span>36.90%</span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="age common" v-if="this.$i18n.locale=='zh'">
            <span>员工性别比例</span>
            <!-- <img src="/img/hr/2.bmp" alt=""> -->
            <div class="gender-detail">
              <ul>
                  <li>
                      <span>男性</span>
                       <span>71.81%</span>
                  </li>
                   <li>
                      <span>女性</span>
                       <span>28.19%</span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="age common" v-if="this.$i18n.locale=='en'">
            <span>Percentage of employees by gender</span>
            <!-- <img src="/img/hr/engender0322.png" alt=""> -->
            <div class="gender-detail">
              <ul>
                  <li>
                      <span>Male</span>
                       <span>71.81%</span>
                  </li>
                   <li>
                      <span>Female</span>
                       <span>28.19%</span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="age common" v-if="this.$i18n.locale=='zh'">
            <span>高管性别比例</span>
            <!-- <img src="/img/hr/leaderpic1.png" alt=""> -->
            <div class="leader-gender-detail">
              <ul>
                  <li>
                      <span>男性高管</span>
                       <span>44.44%</span>
                  </li>
                   <li>
                      <span>女性高管</span>
                       <span>55.56%</span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="age common" v-if="this.$i18n.locale=='en'">
            <span>Percentage of senior management by gender</span>
            <!-- <img src="/img/hr/engao.png" alt=""> -->
            <div class="leader-gender-detail">
              <ul>
                  <li>
                      <span>Male</span>
                       <span>44.44%</span>
                  </li>
                   <li>
                      <span>Female</span>
                       <span>55.56%</span>
                  </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="platform feature">
          <div class="platform-left">
            <h4>{{$t('hr.platform')}}</h4>
            <p>{{$t('hr.platformcontent')}}</p>
            <ul>
              <li class="platform-detail" >
                <span class="num">{{zh_data.hours}}</span>
                <span class="platform-detail-text">{{$t('hr.platformtopunit')}}</span>
                <span class="platform-span">{{$t('hr.platformtopdetail')}}</span>
              </li>
              <li>

                <span class="num">{{zh_data.session}}</span>
                <span class="platform-detail-text">{{$t('hr.platformcenterunit')}}</span>
                <span class="platform-span">{{$t('hr.platfromcenterdetail')}}</span>
              </li>
              <li class="platformli-3">
                <span class="num">{{zh_data.peoplenum}}</span>
                <span class="platform-detail-text">{{$t('hr.platformbottomunit')}}</span>
                <span class="platform-span">{{$t('hr.platformbottomdetail')}}</span>
              </li>
            </ul>
            <div class="platform-scale" v-if="this.$i18n.locale=='zh'">
              <!-- <img src="/img/hr/gradupic.png" alt=""> -->
              
              <ul class="platform-scale-timer">
                <li>
                  <span class="platform-scale-smalltitle">普通员工</span>
                   <span class="platform-scale-hours">{{zh_data.ordinary}} <small>小时</small></span>
                </li>
                <li>
                  <span class="platform-scale-smalltitle">中级管理层</span>
                   <span class="platform-scale-hours">{{zh_data.middle}} <small>小时</small></span>
                </li>

                <li>
                  <span class="platform-scale-smalltitle">高级管理层</span>
                   <span class="platform-scale-hours">{{zh_data.senior}} <small>小时</small></span>
                </li>
              </ul>
              <span>人均受训小时数</span>
            </div>
            <div class="platform-scale" v-if="this.$i18n.locale=='en'">
              <!-- <img src="/img/hr/managerment.png" alt=""> -->
             
              <ul class="platform-scale-timer">
                <li>
                  <span style ="opacity:0">Management</span>
                  <span  class="platform-scale-smalltitle">Employees</span>
                  
                   <span class="platform-scale-hours">{{zh_data.ordinary}}<small>Hours</small></span>
                </li>
                <li>
                  <span  class="platform-scale-smalltitle">Middle</span>
                  <span  class="platform-scale-smalltitle">Management</span>
                   <span class="platform-scale-hours">{{zh_data.middle}}<small>Hours</small></span>
                </li>
      
                <li>
                  <span  class="platform-scale-smalltitle">Senior</span>
                  <span  class="platform-scale-smalltitle">Management</span>
                   <span class="platform-scale-hours">{{zh_data.senior}}<small>Hours</small></span>
                </li>
              </ul>
              <span>Average Number of Training Hours per Employee</span>
            </div>

          </div>

        </div>

        <div class="degree feature" >
          <h4>{{$t('hr.degreetitle')}}</h4>
          <div class="degree-content">
            <div class="degree-content-left">

              <p>{{$t('hr.degreecontent')}}</p>
              <p  style="padding-top: 30px;">{{$t('hr.degreecontent1')}} </p>

            </div>
            <div class="degree-content-right">
              <span>{{$t('hr.degreecontentright')}}</span>
              <div class="degree-content-right-spandetail">
                <span class="num">4.6</span>
                <span class="text">{{$t('hr.degreecontentrighttext')}}</span>
              </div>
            </div>
          </div>
          <div class="question" v-if="this.$i18n.locale=='zh'">
            <!-- <img src="/img/hr/question1.png" alt=""> -->
            <div class="question-detail">
              <ul>
                  <li>
                      <span>共发放问卷1708份，收到有效反馈1622份</span>
                       <span>员工参与率：<strong>95%</strong></span>
                  </li>
                   <li>
                  </li>
              </ul>
            </div>
          </div>
          <div class="question" v-if="this.$i18n.locale=='en'">
            <!-- <img src="/img/hr/enquestion.png" alt=""> -->
            <div class="question-detail">
              <ul>
                  <li>
                      <span>1,708 questionnaires were distributed, 1,622 valid feedbacks received</span>
                       <span>Employee participation rate：<strong>95%</strong></span>
                  </li>
                   <li>
                  </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="hr-health feature">
          <h3>{{$t('hr.noticeHelth')}}</h3>
          <p>
            {{$t('hr.noticeContent')}}
          </p>
        </div>
        <div class="hr-banner feature">
          <div v-if="this.$i18n.locale=='zh'">
            <h4 >关爱员工</h4>
            <p>SOHO中国持续为员工提供有温度的关爱措施，2023年，我们在行动：</p>
            <p>关注办公环境，定期消毒消杀，提升空气质量；</p>
            <p>关注员工健康，增加常备药品种类，不定期发布健康小贴士；</p>
            <p >关注员工健康，配置健身器材等活动设施，常设足球、籃球、羽毛球、乒乓球俱乐部、乐跑团、瑜伽课程等兴趣小组，定期举办活动；</p>
            <p>关注员工心理健康，配备商业保险并增加心理咨询服务。</p>
          </div>
          <div v-if="this.$i18n.locale=='en'">
            <h4>EMPLOYEE CARE</h4>
            <p>
            SOHO China has been providing employees with warm care.These were what we have done in 2023 : 
            </p>
            <p>Pay attention to the office environment, disinfect regularly, and improve the air quality; 
            </p>
            <p>Pay attention to the health of employees, increase the types of standing medicines, and release health tips from time to time; 
            </p>
            <p>
              We have clubs of football, basketball, badminton, ping pong and running clubs for employees. The clubs host regular activities twice a month.
            </p>
            <p>Pay attention to the psychological wellness of employees, provide commercial insurance and add psychological counseling services.
            </p>
          </div>

          <!-- <div class="banner-swiper">
            <hr-swiper :imglist="imglist"></hr-swiper>
          </div> -->
          <div class="banner-swiper" @click="videoplay">
            <!-- <img src="../assets/videoposter.jpg" alt="" style="display: block; width:100%">  -->
            <img src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240313154307.jpg" alt="" style="display: block; width:100%"> 
          </div>
        </div>
      </div>
    </div>
    <div class="video-box">
      <div class="overlay" v-if="showslide">
      </div>
      <div class="video" :class="{'slide':showslide}">
        <div class="video-iconfont" @click="videoClose">
          <icon name="cross"  size="30" color="#fff"/>
        </div>
      
        <!-- <video  ref="video" src="https://cdn.cnbj1.fds.api.mi-img.com/product-images/xiaomi-13kb7buy/v1-0.mp4" controls></video> -->
       
        <!-- <video  ref="video" src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/soho.mp4" controls></video> -->
        <video  ref="video" src=" https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/2023-0918%20SOHO%20%E8%BF%90%E5%8A%A8%E4%BC%9A%20100m.m4v" controls></video>
      </div>
    </div>
  </div>


</template>

<script>
// import HrSwiper from "../components/Swiper"
import NavHeader from '../components/Header'
import { getdataInfo } from "../api/home";
import {mapState} from 'vuex'
import { Icon } from 'vant';
export default {
  name: "Hr",
  components:{
    //  HrSwiper,
    NavHeader,
    Icon
  },
  data (){
    return{
      showslide:false,
      id: 4,
      pageType: 'employee',
      zh_data:{},
      imglist:[]
    }
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
    this.getdata()
  },
  computed:{
    ...mapState({
      lang:state => state.lang
    })
  },
  methods: {
    async getdata() {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
      const zh_envdata= JSON.parse(data.esgContent.cnContent)
      this.zh_data =zh_envdata
      console.log(this.zh_data)
      this.imglist = this.zh_data.hrImgList
    },
    videoClose(){
      this.showslide =false
      let video =this.$refs.video
      video.currentTime = 0;
      video.pause()
    },
    videoplay(){
      this.showslide =true
      setTimeout(()=>{
            this.$refs.video.play()
          },1000)
    }
  }
}
</script>

<style scoped lang="scss">
.main{

  width: 100%;
  background-color: #F5F5F7;
  padding-top: 68px;
  padding-bottom:100px;
  .en .degree{
    h4{
      font-family:"BarlowCondensed-bold";
    }
  }
  .common{
    ul{
       display: flex;
      li{
        padding: 20px 0px 20px 40px;
        font-size: 72px;
        font-family: "BarlowCondensed-bold";
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 0;
        box-sizing: border-box;
        span{
           color: #fff;
           padding-bottom: 0;
           font-size: 22px;
        }
        span:nth-child(1){
           font-family: "Barlow-Regular";
           padding-bottom: 5px;
        }
        span:nth-child(2){
          font-size: 40px;
          font-family: "BarlowCondensed-bold";
        }
      }
    }
  }
  .age-detail{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    ul{
      li:nth-child(1){
        width:20%;
        background-color: #7DD5C9;
      }
        li:nth-child(2){
        width:48%;
        background-color: #24C3AD;
      }
        li:nth-child(3){
        width:32%;
        background-color: #00917D;
      }
    }
  }
  .gender-detail{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    ul{
      width: 100%;
      li:nth-child(1){
        width:72%;
        background-color: #83DADF;
      }
        li:nth-child(2){
        width:28%;
        background-color: #22BAC3;
      }
    }
  }
  .leader-gender-detail{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    ul{
      width: 100%;
      li:nth-child(1){
        width:40%;
        background-color: #8BCBE2;
      }
        li:nth-child(2){
        width:60%;
        background-color: #2299C3;
      }
    }
  }
  .question-detail{
    ul{
       display: flex;
      li{
        padding: 20px 0px 20px 40px;
        font-size: 72px;
        font-family: "BarlowCondensed-bold";
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 0;
        box-sizing: border-box;
        text-align: left;
        span{
          display: block;
           color: #fff;
           padding-bottom: 0;
           font-size: 20px;
        }
        span:nth-child(1){
           font-family: "Barlow-Regular";
           padding-bottom: 5px;
        }
        span:nth-child(2){
          font-size: 20px;
          font-family: "Barlow-Regular";
          strong{
            font-family: "BarlowCondensed-bold";
            font-size: 34px;
          }
        }
      }
      li:nth-child(1){
        width:92%;
        background-color: #00917D;
      }
        li:nth-child(2){
        width:8%;
        background-color: #7DD5C9;
      }
    }
  }
  .degree{
    background: #fff;
    text-align: center;
    padding:0px 60px 80px;
    border-radius: 30px;
    h4{
      font-size: 44px;
      color:rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;
      padding:50px 0;
    }

  }
  .degree-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .en .degree-content-left{
    p{
      font-family: "Barlow-Regular";
      letter-spacing: 1px;
    }
  }
  .degree-content-left{
    width: 80%;
    p{
      font-size: 18px;
      line-height: 30px;
      color: #353535;
      text-align: justify;
      letter-spacing: 2px;

    }
  }
  .en .hr-health{
    h3{
      font-family:"BarlowCondensed-bold";
    }
    p{
      font-family: "Barlow-Regular";
    }

  }
  .hr-health{
    text-align: center;
    h3{
      font-size: 44px;
      color: #22c3ad;
      padding: 120px 0 60px;
      font-weight: bold;
    }
    p{
      font-size: 24px;
      letter-spacing: 1px;
      color: #353535;
      text-align: left;
    }
    h4{
      font-size: 36px;
      color: #22c3ad;
      font-weight: bold;
    }
  }
  .en .degree-content-right-spandetail .num{
    font-family: "BarlowCondensed-bold";
  }
  .degree-content-right-spandetail{
    margin-top: -16px;
    margin-left: -22px;
  }
  .en  .degree-content-left{
    width: 100%;
  }
  .degree-content-right{
    width: 20%;
    margin-left: 70px;
    box-sizing: border-box;
    margin-top: 30px;

  }
  .question {
    padding-top: 50px;
    img{
      display: block;
      width: 100%;
    }
  }
  .en .degree-content-right{
    width: 15%;
    text-align: center;
    span{
      font-family: "Barlow-Regular";
    }

  }

  .degree-content-right .num{
    font-size: 86px;
    font-family:"BarlowCondensed-bold";
    color:rgb(53, 53, 53);
  }
  .degree-content-right .text{
    font-size: 32px;
    color:rgb(53, 53, 53);
    font-family: "BarlowCondensed-bold";
    font-weight: bold;
    letter-spacing: 2px;
  }
  .en .degree-content-right .text{
    font-family: "BarlowCondensed-bold";
  }
  .en .common{
    span{
      font-family: "Barlow-Regular";
    }
  }
  .en  .common{
      span{
        letter-spacing: 0;
      }
  }
  .common{
    padding: 80px 60px 0;
    span{
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 30px;
      color: #353535;
      display: block;
      padding-bottom:10px;
    }
    img{
    display: block;
    width: 100%;
  }
  }
  .hr-protect{
    background: #FFFFFF;
    border-radius: 30px;
    padding-top: 66px;
    margin-top: 30px;
    padding-bottom: 90px;
    position: relative;
  }

  .hr-protect h4{
    font-size: 44px;
    color:rgb(34, 195, 173);
    font-weight: bold;
    text-align: center;

  }
  .en .hr-protect h4{
    text-align: center;
    font-family: "BarlowCondensed-bold";
  }
  .main .en .platform-span{
    margin-left: 0;
  }

  .hr-flex{
    padding-top:95px;

    display: flex;
    flex-direction: row;

    box-sizing: border-box;
  }

  .hr-protect-left{
    width: 100%;
    box-sizing: border-box;
    padding:0 60px;
    display: flex;
  }
  .en .hr-protect-left{
    padding: 0 30px;
  }
  .en .hr-protect-left span{
    font-family: "BarlowCondensed-bold";
  }
  .hr-protect-left p{
    font-size:18px;
    letter-spacing: 2px;;
    line-height:30px;
    color: #353535;
    text-align: justify;
    font-family: "Barlow-Regular";
  }
  .en  .hr-protect-left p{
    letter-spacing: 0px;

  }
  .hr-protect-left-lefts{
    width:48%;
  }
  .hr-left-flex{
    width: 54%;
    display: flex;
    box-sizing: border-box;
    padding-left: 100px;
  }
  .hr-left-flex span{
    font-size: 18px;
  }
  .hr-protect-left-left span:nth-child(1){
    font-size: 18px;
  }

  .hr-protect-left-left span:nth-child(2){
    font-size: 72px;
    color: #353535;
    letter-spacing: 3px;
    font-family: "BarlowCondensed-bold";
  }
  .hr-protect-left-left span:nth-child(3){
    font-size: 36px;
    color: #353535;
    font-family: "BarlowCondensed-bold";
    font-weight: bold;
    padding-left: 5px;
  }
  .hr-protect-left-right{
    padding-left: 20px;
  }

  .hr-protect-left-right span:nth-child(2){
    font-size: 72px;
    color: #353535;
    font-family: "BarlowCondensed-bold";
    letter-spacing: 3px;
  }
  .hr-protect-left-right span:nth-child(3){
    font-size: 36px;
    color: #353535;
    font-family: "BarlowCondensed-bold";
    font-weight: bold;
  }
  .hr-protect-left-left{
    width: 50%;
    vertical-align: middle;
  }


  /* 知识管理平台 */

  .platform{
    display: flex;
    flex-direction: row;
    background:#22c3ad;
    border-radius: 30px;
    margin-top: 70px;
    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;
    color: #fff;
    margin-bottom: 70px;
  }
  .platformli-3{
    margin-top: 0px;
    padding-left: 32px !important;
  }
  .platform span{
    font-size: 18px;
    margin-top: -10px;
  }
  .en .platform span {

    font-family: "BarlowCondensed-bold";
  }

  .platform-left{
    width: 100%;
    padding: 0 60px;
  }
  .en  .platform-left p{
    font-family: "Barlow-Regular";
  }
  .platform-left p{
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
    padding: 50px 0;
  }
  .platform-left h4{
    font-size: 44px;
    color: #fff;;
    font-weight: bold;
    text-align: center;
    font-family: "BarlowCondensed-bold";
  }
  .en .platform-left h4{
    text-align: center;
  }
  .platform-left ul {
    display:flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .platform-left ul li{
    width: 32%;
    box-sizing: border-box;
    padding-left:0px;
  }
  .platform-left ul li:nth-child(2){
    padding-left: 16px;
  }

  .platform .platform-detail-text{
    font-size: 36px;
    margin-left: 5px;
    font-family: "BarlowCondensed-bold";
  }
  .platform-scale{
    text-align: center;
    .platform-scale-timer{
      display: flex;
      box-sizing: border-box;
      align-items: flex-end;
      text-align: left;
      padding: 20px 0;
      li{
        width: 33%;
        background-color: #FEFEFE;
        box-sizing: border-box;
        padding-left: 90px;
        span{
          color: #646464;
          display: block;
          margin-top:-5px;
          
        }
        .platform-scale-smalltitle{
          font-size: 20px;
        }
        .platform-scale-hours{
          font-size: 42px;
          color:#00917C;
          font-weight: 800;
          font-family: "BarlowCondensed-bold";
          small{
            font-family: Barlow-Regular;
            margin-left:-5px;
          }
        }
      }
      li:nth-child(1){
        height: 130px;
        padding-top: 35px;
        padding-bottom: 20px;
      }
      li:nth-child(2){
        height: 150px;
        padding-top: 55px;
        margin-left: 0.5%;
        padding-left: 90px;
      }
      li:nth-child(3){
        height: 170px;
        padding-top: 75px;
        margin-left: 0.5%;
      }
    }
    span{
      font-size: 24px;
      letter-spacing: 1px;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  .en .platform-scale{
    .platform-scale-timer{
      .platform-scale-smalltitle{
          font-size: 20px;
          letter-spacing: 1px;
          font-family: Barlow-Regular;
         
        }
      li:nth-child(1){
        height: 130px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      li:nth-child(2){
        height: 150px;
        padding-top: 40px;
        margin-left: 0.5%;
        padding-left: 90px;
      }
      li:nth-child(3){
        height: 170px;
        padding-top: 60px;
        margin-left: 0.5%;
      }
    }
  }
  .platform-span{
    display: block;

    margin-left: 0px;
  }
  .en .platform-span{
    font-size: 24px;
    margin-left: 0px;
  }
  .platform .num{
    font-size: 72px;
    font-family: "BarlowCondensed-bold";
    font-weight: bold;
    letter-spacing: 2px;
  }

  /* 轮播图模块 */
  .hr-banner{
    text-align: center;
    h4{
      padding-top: 160px;
      padding-bottom: 30px;
      font-size: 44px;
      color:#22c3ad;
      font-weight: bold;
      text-align: center;
      font-family: "BarlowCondensed-bold";
    }
   p{
     font-size: 24px;
     letter-spacing: 1px;
     color:#353535;
     line-height: 37px;
     padding-top: 20px;
   }
    .banner-swiper{
      padding-top: 30px;
    }

  }
  .en .hr-banner{
    text-align: center;
  }
  .en .hr-banner p{
    font-family: "Barlow-Regular";
    letter-spacing: 0px;
  }

}
.video-box{
    .overlay{
      position:fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.8;
    }
    .video{
      position: fixed;
      left:50%;
      top: -50%;
      transform: translate(-50%,-50%);
      transition: all 0.6s;
      opacity: 0;
      width: 1000px;
      height: 536px;
      &.slide{
        top: 50%;
        opacity: 1;
      }
      .video-iconfont{
        width: 30px;
        height: 30px;
        position:absolute;
        right:20px;
        top: 20px;
        cursor: pointer;
        z-index: 999;
        // background-color: #00917C;
      }
      video{
        width:100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

@media(max-width:640px) {
  .main{
    padding-top: 35px;
    padding-bottom:50px;
    .hr-protect{
      width: 94%;
      margin: 0 auto;
      background: #FFFFFF;
      border-radius: 10px;
      position: relative;
      padding: 66px 10px 50px;

    }
    .en .degree-content-left {
      width: 100%;
    }
    .en .degree-content-right {
      width: 100%;
    }
    .banner-swiper img{
      width: 100%;
      height: auto;
    }
    .common{
        ul{
          display: flex;
          li{
            padding: 10px 0 10px 10px;
            font-family: "BarlowCondensed-bold";
            font-weight: bold;
            letter-spacing: 2px;
            font-size: 0;
            box-sizing: border-box;
            span{
              color: #fff;
              padding-bottom: 0;
              font-size: 14px;
            }
            span:nth-child(1){
              font-family: "Barlow-Regular";
              padding-bottom: 0px;
            }
            span:nth-child(2){
              font-size: 24px;
              font-family: "BarlowCondensed-bold";
              margin-top: -5px;
            }
          }
        }
  }
  .question-detail{
    ul{
       display: flex;
      li{
        padding: 10px 0px 10px 10px;
        font-family: "BarlowCondensed-bold";
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 0;
        box-sizing: border-box;
        text-align: left;
        span{
          display: block;
           color: #fff;
           padding-bottom: 0;
           font-size: 14px;
        }
        span:nth-child(1){
           font-family: "Barlow-Regular";
           padding-bottom: 5px;
        }
        span:nth-child(2){
          font-size: 14px;
          font-family: "Barlow-Regular";
          strong{
            font-family: "BarlowCondensed-bold";
            font-size: 16px;
          }
        }
      }
      li:nth-child(1){
        width:92%;
        background-color: #00917D;
      }
        li:nth-child(2){
        width:8%;
        background-color: #7DD5C9;
      }
    }
  }
    .hr-protect h4{
      font-size: 28px;
      color:rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;
    }
    .en  .hr-protect h4{
      text-align: left;
      padding-left: 10px;
    }
    .hr-flex{
      padding-top:35px;
      display: flex;
      flex-direction: column;
    }

    .hr-protect-left{
      width: 100%;
      box-sizing: border-box;
      padding:0px;
      flex-direction: column;
    }
    .hr-protect-left-right{
      padding-left: 0px;
    }
    .hr-protect-left-lefts{
      width: 100%;
    }
    .common {
      padding: 30px 0 0;
      span{
        font-size: 16px;
      }
    }
    .hr-protect-left-left span:nth-child(1){
      font-size: 16px;
    }
    .platform-left{
      padding-bottom: 45px;
    }
    .platform-left p{
      padding: 30px 0;
      text-align: left;
      font-size: 14px;
      line-height: 1.5;
    }
    .degree-content-left{
      width: 94%;
    }
    .degree-content-right{
      width: 94%;
      margin-left: 0;
    }
    .question{
      padding-top: 5px;
    }
    .degree{
      padding: 0 0 50px;
      h4{
        font-size: 26px;

      }
      p{
        font-size: 14px;
        line-height: 1.5;
      }
    }
    .degree-content{
      flex-direction: column;
    }

    .platformli-3{
      padding-left: 0px !important;
    }
    .hr-protect-left p{
      font-size:14px;
      letter-spacing: 2px;;
      line-height: 1.5;

    }
    .hr-left-flex{
      padding-top:30px;
      width: 100%;
      padding-left: 0;
    }
    .hr-left-flex span{
      font-size: 14px;
    }
    .hr-protect-right{
      width:100%;
    }

    .hr-protect-left-left span:nth-child(2){
      font-size: 36px;
      color: #353535;
      font-family: "BarlowCondensed-bold";
    }
    .hr-protect-left-left span:nth-child(3){
      font-size: 20px;
      color: #353535;
      font-family: "BarlowCondensed-bold";
      font-weight: bold;
    }
    .en .platform-left ul{
      padding-top: 0px;
      text-align: left;
      margin-left: 0px;
    }
    .en .platform-span{
      margin-left: 0;
    }
    .hr-protect-left-right span:nth-child(2){
      font-size: 36px;
      color: #353535;
      font-family: "BarlowCondensed-bold";
    }
    .hr-protect-left-right span:nth-child(3){
      font-size: 20px;
      color: #353535;
      font-family: "BarlowCondensed-bold";
      font-weight: bold;
    }
    .hr-protect-left-left{
      float: none;
      vertical-align: middle;
    }
    .en .hr-protect-left{
      padding: 0 10px;
    }
    .hr-protect-left-right{
      float: none;
      vertical-align: middle;

    }
    .platform-left ul li:nth-child(2){
      padding-left: 0px;
    }
    .hr-protect-right-left img{
      width: 150px;
      height: 150px;
      margin-left: 0;
      padding-top: 35px;
    }

    .hr-protect-right-right {
      position: absolute;
      right: 28px;
      bottom: 56px;
    }
    .hr-protect-right-right li{
      margin-top: 20px;
    }
    .role{
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color:rgb(22, 116, 151);
      margin-right: 10px;
    }
    .hr-protect-right-right .role2{
      background-color:rgb(34, 195, 173);
    }
    .hr-protect-right-right .role3{
      background-color: rgb(204, 236, 218);
    }


    /* 知识管理平台 */

    .platform{
      display: flex;
      flex-direction: column;
      width:100%;
      margin: 0 auto;
      background:#22c3ad;
      border-radius: 10px;
      margin-top: 30px;
      box-sizing: border-box;
      color: #fff;
      margin-bottom: 50px;
      text-align: center;
      box-sizing: border-box;
      padding: 60px 10px 0;
    }
    .platformli-3{
      margin-top: 0;

    }
    .platform span{
      font-size: 18px;
      margin-top: -5px;
    }
    .platform-left{
      width: 100%;
      padding-left: 0px;

    }
    .platform-left h4{
      font-size: 26px;
      color: #fff;;
      font-weight: bold;
      text-align: center;
    }
    .en .platform-left h4{
      text-align: left;
    }
    .platform-left ul {
      display:flex;
      flex-direction: column;
      justify-content: center;
      text-align:center;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 0px;
      margin-left: 0px;
    }
    .platform-left ul li{
      margin-top: 10px;
      width: 100%;
    }

    .platform .platform-detail-text{
      font-size: 28px;
      margin-left: 5px;
      font-family: "BarlowCondensed-bold";
    }
    .platform-span{
      display: block;

      margin-left:1px;
    }
    .platform .num{
      font-size: 40px;
      font-family: "BarlowCondensed-bold";
      font-weight: bold;
    }

    .hr-health{
      width: 94%;
      margin: 0 auto;

      h3{
        font-size: 26px;
        padding: 60px 0 40px;
      }
      p{
        font-size: 16px;
        text-align: left;
      }
      h4{
        font-size: 20px;
        text-align: center;

      }
    }
    /* 轮播图模块 */
    .hr-banner{
      padding-top: 0px;
      text-align: center;
      h4{
        padding-top: 90px;
        font-size: 26px;
      }
    }
    .en .hr-banner{
      padding: 0 10px;
      text-align: left;
    }
    .hr-banner p{
      padding: 0 10px;
      font-size: 14px;
      letter-spacing: 1px;
      color:rgb(57, 57, 57);
      line-height:1.5;
      text-align: left;
    }
    .platform-scale{
    text-align: center;
    .platform-scale-timer{
      display: flex;
      box-sizing: border-box;
      align-items: flex-end;
      flex-direction: row;
      text-align: left;
      li{
        width: 33%;
        background-color: #FEFEFE;
        box-sizing: border-box;
        padding-left: 10px;
        span{
          color: #646464;
          display: block;
          margin-top:-5px;
          
        }
        .platform-scale-smalltitle{
          font-size: 14px;
        }
        .platform-scale-hours{
          font-size: 22px;
          color:#00917C;
          font-family: "BarlowCondensed-bold";
          small{
            font-family: Barlow-Regular;
            margin-left:-5px;
          }
        }
      }
      li:nth-child(1){
        height: 80px;
        padding-top: 25px;
        padding-bottom: 20px;
      }
      li:nth-child(2){
        height: 90px;
        padding-top: 35px;
        margin-left: 0.5%;
        padding-left: 10px;
      }
      li:nth-child(3){
        height: 110px;
        padding-top: 55px;
        margin-left: 0.5%;
      }
    }
    span{
      font-size: 24px;
      letter-spacing: 1px;
    }
    img{
      display: block;
      width: 100%;
    }
    }
    .en .platform-scale{
    .platform-scale-timer{
      .platform-scale-smalltitle{
          font-size: 14px;
          letter-spacing: 1px;
          font-family: Barlow-Regular;
         
        }
      li:nth-child(1){
        height: 80px;
        padding-top: 15px;
        padding-bottom: 20px;
      }
      li:nth-child(2){
        height: 90px;
        padding-top: 25px;
        margin-left: 0.5%;
        padding-left: 20px;
      }
      li:nth-child(3){
        height: 100px;
        padding-top: 25px;
        margin-left: 0.5%;
      }
    }
  }
  .age-detail{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    ul{
      li:nth-child(1){
        width:23%;
        background-color: #7DD5C9;
      }
        li:nth-child(2){
        width:48%;
        background-color: #24C3AD;
      }
        li:nth-child(3){
        width:29%;
        background-color: #00917D;
      }
    }
  }
  
  }
  .video-box{
  
    .video{
     
      width: 100%;
      height: auto;
      &.slide{
        top: 50%;
        opacity: 1;
      }

    }
  }

}
</style>
